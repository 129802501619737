:root{
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

.footer-container
{
    margin-top: 1rem;
    background-color: var(--shadowHigh);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    margin-top: 0.25rem;
}
