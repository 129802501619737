:root{
    --main-title-color: black;
    --subtitle-color: grey;
    --background-main-title-color: white;
    --background-subtitle-color: white;
    --accentHigh: #ffffff;
    --accentLow: #ffe484;
    --maincolor: #ffcc33;
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

@font-face{
    font-family: 'Edwardian Script ITC';
    font-style: normal;
    font-weight: normal;
    src: url('../Assets/Fonts/EdwardianScriptITC.ttf');
}

.text-container{
    padding: 0;
    margin: 0;
}

.logo{
    height: 100%;
    transform: translate(0px, 50px);
}

.main-title-container{
    background-color: var(--maincolor);
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container{
    height: 30rem;
    background-color: var(--maincolor);
}

.main-title-container-small{
    background-color: var(--maincolor);
    height: 20rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container-small{
    height: 10rem;
}

.title-text{
    color: var(--shadowHigh);
    font-size: 4rem;
    font-family: Edwardian Script ITC;
    text-align: left;
}

.subtitle-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--maincolor);
}

.subtitle-text{
    color: var(--shadowHigh);
    font-family: Edwardian Script ITC;
    font-size: 4rem;
}

.headerStyle{
    text-decoration: inherit;
}

@media screen and (max-width: 480px){
    .main-title-container{
        background-color: var(--maincolor);
        height: max-content;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .logo{
        height: 70%;
    }

    .subtitle-text{
        font-size: 3em;
    }

    .text-container{
        margin: 0;
        padding: 0;
    }
}

@media screen and (max-width: 768px){
    .main-title-container{
        background-color: var(--maincolor);
        height: max-content;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .main-title-container{
        height: max-content;
    }

    .text-container{
        margin: 0;
        padding: 0;
    }

    .main-title-container-small .title-text{
        font-size: 2rem;
    }
}