:root{
    --main-title-color: black;
    --subtitle-color: grey;
    --background-main-title-color: white;
    --background-subtitle-color: white;
    --accentHigh: #ffffff;
    --accentLow: #ffe484;
    --maincolor: #ffcc33;
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

@font-face{
    font-family: 'Edwardian Script ITC';
    font-style: normal;
    font-weight: normal;
    src: url('../Assets/Fonts/EdwardianScriptITC.ttf');
}

.category-title-label{
    border-bottom: 5px solid var(--shadowLow);
}

.category-title-container{
    color: var(--shadowHigh);
    font-family: Edwardian Script ITC;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-tile-item-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.tile-item-container{
    display: flex;
    flex-wrap: wrap;
}
