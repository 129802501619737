:root{
    --accentHigh: #ffffff;
    --accentLow: #ffe484;
    --maincolor: #ffcc33;
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

.featured-item-container{
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.alignment-container{
    width: 55rem;
    display: flex;
}

.feature-text-container{
    margin-left: 1rem;
    padding-top: 1rem;
}

.title-container{
    margin-bottom: 1rem;
}

.image-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.description-container{
    text-align: justify;
}

@media screen and (max-width: 480px){
    .alignment-container{
        width: 55rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    .feature-text-container{
        width: 90%;
    }
}

@media screen and (max-width: 768px){
    .alignment-container{
        width: 35rem;
        display: flex;
    }
}