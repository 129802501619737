:root{
    --accentHigh: #ffffff;
    --accentLow: #ffe484;
    --maincolor: #ffcc33;
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

.message-container
{
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 2rem;
    background-color: var(--maincolor);
    color: black;
}

.message-items-container
{
    text-align: justify;
    width: 55rem;
}