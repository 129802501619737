
@font-face{
    font-family: 'Edwardian Script ITC';
    font-style: normal;
    font-weight: normal;
    src: url('../Assets/Fonts/EdwardianScriptITC.ttf');
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.coupon{
    display: flex;
    justify-content: center;
}

.coupon-btn{
    width: 5rem;
    margin-left: 1rem;
    border-radius: 15px;
}

.CheckoutPage{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
}

.flex{
     display: flex;
}

.center{
    justify-content: center;
    align-items: center;
}

.margin{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.littlemargin{
    margin: 1rem;
}

.customerInformation{
    margin-bottom: 1rem;
}

.btn-tab{
    display: flex;
    margin-bottom: 1rem;
}

.paypal-btn{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.stripe-btn{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.stripe-content-area{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.paypal-info-content-area{
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}

.checkoutLabel{
    font-family: Edwardian Script ITC;
    border-bottom: 3px solid #fc9601;
}

.fillscreen{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 1000;
}

.loader{
    position: absolute;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #fc9601; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

.lilloader{
    position: absolute;
    top: 50%;
    right: 50%;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #fc9601; /* Blue */
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

.hover{
    border-radius: 15px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    background-color: lightgray;
}

.hover:hover{
    background-color: darkgray;
    user-select: none;
}

.coupon-input{
    width: 20rem;
}

input{
    border-top: none;
    border-left: none;
    border-right: none;
    width: inherit;
    margin: 1rem;
}

input:hover{
    background-color: #efefef;
}

@media screen and (max-width: 784px){
    .centerContent{
        width: 640px;
    }
}

@media screen and (max-width: 640px){
    .centerContent{
        width: 480px;
    }
}

@media screen and (max-width: 480px){
    .centerContent{
        width: 480px;
    }
}
