
@media screen and (max-width: 480px){
    .cart-open-container{
        width: 100%;
    }

    .items-container{
        position: absolute;
        left: 0;
        top: calc(3rem + 1px);
    }
}

th, td
{
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
}