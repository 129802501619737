.floating-button-container{
    width: 300px;
    height: 175px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floating-button{
    width: 200px;
    height: 70px;
    border-radius: 200px;
    background-color: #d14009;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffe484;
    text-emphasis: none;
}

.floating-button:hover{
    background-color: #fc9601;
}

@media screen and (max-width: 425px){
    .floating-button-container{
        width: 200px;
        height: 175px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .floating-button{
        width: 100px;
        height: 50px;
        border-radius: 200px;
        background-color: #d14009;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffe484;
        text-emphasis: none;
    }
}