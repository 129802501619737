:root{
    --accentHigh: #ffffff;
    --accentLow: #ffe484;
    --maincolor: #ffcc33;
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

.product-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}


@media screen and (max-width: 768px){
    .product-container{
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 480px){
    .product-container{
        display: flex;
        flex-wrap: wrap;
    }
}