:root{
    --accentHigh: #ffffff;
    --accentLow: #ffe484;
    --maincolor: #ffcc33;
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

@font-face{
    font-family: 'Edwardian Script ITC';
    font-style: normal;
    font-weight: normal;
    src: url('../Assets/Fonts/EdwardianScriptITC.ttf');
}

.meaning-font{
    font-family: Edwardian Script ITC;
    font-size: 2em;
}

.product-description{
    margin: 1rem;
}

.description-container{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.button-container{
    display: flex;
}

.unit-container{
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray
}

.increment-btn{
    width: 2rem;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-right: 1px solid black;
}

.decrement-btn{
    width: 2rem;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.rounded-btn{
    border-radius: 15px;
}

.littlemargin{
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

button{
    width: 100%;
    height: 2rem;
    border: none;
}


button:hover{
    background-color: darkgray;
}