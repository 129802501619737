:root{
    --tile-padding: 0.25rem;
    --accentHigh: #ffffff;
    --accentLow: #ffe484;
    --maincolor: #ffcc33;
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

.item-tile-container{
    width: 200px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.item-tile-image-container{
    width: 200px;
    height: 200px;
    position: relative;
}

@keyframes imageHover{
    to {
        filter: blur(2px);
        filter: brightness(1.25);
    }
}

@keyframes container_hover_v2
{
    to
    {
        transform: scale(120%);
    }
}

.item-tile-image-container:hover{
    animation-name: imageHover;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

.item-tile-title{
    padding: var(--tile-padding);
    text-align: center;
    background-color: var(--shadowLow);
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.item-tile-description{
    padding: var(--tile-padding);
}

.tile-item-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.link-container{
    text-decoration: inherit;
    color: inherit
}

.item-tile-available{
    width: 200px;
    height: 200px;
    position: relative;
    filter: brightness(0.25);
}

.overlay-text{
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5vh;
    z-index: 5;
}

.item-tile-container-v2
{
    position: relative;
    padding: 1em;
}

.item-tile-container-v2:hover
{
    animation: container_hover_v2 0.2s linear 0s;
    animation-fill-mode: forwards;
}

.item-tile-available-v2
{
    filter: brightness(0.25);
}

.item-tile-title-v2
{
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    text-shadow: 2px 2px black;
    text-align: center;
    z-index: 1;
    transform: translate(-50%, -50%);
    font-size: 3.5vh;
}

.item-image-circle-v2
{
    border-radius: 200px;
    border-color: white;
    border-width: 5px;
    border-style: solid;
    box-shadow: 5px 5px rgba(48, 48, 48, 0.24);
}