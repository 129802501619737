@keyframes background_image_translation {
    from{
        background-position-y: 0%;
    }

    to{
        background-position-y: 25%;
    }
}

@font-face{
    font-family: 'Edwardian Script ITC';
    font-style: normal;
    font-weight: normal;
    src: url('../Assets/Fonts/EdwardianScriptITC.ttf');
}

.banner_height
{
    height: 300px;
    margin-top: 2em;
}

.banner_image_container
{
    height: 200px;
    width: 200px;
    margin: 1em;
}

.banner_image
{
    height: inherit;
    width: inherit;
    border-radius: 500px;
    border-width: 5px;
    border-style: solid;
    border-color: white;
}

.banner_background_color
{
    background-color: black;
    background-image: url('../Assets/Images/Candles/background_white.jpg');
    animation-name: background_image_translation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
}

.banner_flex
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_color
{
    color: rgb(48, 48, 48);
    font-size: 4rem;
    text-shadow: 2px 2px rgb(255, 255, 255);
    text-align: center;
    font-family: Edwardian Script ITC;
    padding: 1em;
}