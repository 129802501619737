@font-face{
    font-family: 'Edwardian Script ITC';
    font-style: normal;
    font-weight: normal;
    src: url('../Assets/Fonts/EdwardianScriptITC.ttf');
}


.photo-gallery-main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2vh;
}

.photo-gallery-view-container{
    display: flex;
    align-items: center;
    margin: 2vh;
    width: 900px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.photo-gallery-photo-container{
    margin: 1vh;
}

.photo-gallery-title-container{
    font-size: 10vh;
    text-align: center;
    font-family: Edwardian Script ITC;
}

.overlayImage{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #444444a4;
}

.little-x{
    position: relative;
    top: 0;
    right: 0;
    color: white;
}

@media only screen and (max-width: 414px){
    .photo-gallery-view-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2vh;
        width: 900px;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 375px){
    .photo-gallery-view-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2vh;
        width: 900px;
        flex-wrap: wrap;
    }
}