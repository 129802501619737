.content-grid
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.items
{
    width: 55rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}