:root{
    --accentHigh: #ffffff;
    --accentLow: #ffe484;
    --maincolor: #ffcc33;
    --shadowLow: #fc9601;
    --shadowHigh: #d14009;
}

.Homepage{
    background-color: var(--maincolor);
}

.video-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: blue;
}

.category-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-box{
    display: flex;
    width: 55rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.category-button{
    width: 400px;
    height: 400px;
    margin: 0.5rem;
    border-radius: 100%;
}

.flex{
    display: flex;
}

.flex-center{
    justify-content: center;
}

.flex-left{
    justify-content: flex-start;
}

.flex-right{
    justify-content: flex-end;
}

.message-header{
    color: var(--shadowHigh);
    padding: 0.5rem;
    border-bottom: 3px solid var(--accentLow);
}

@media screen and (max-width: 480px){
    .center-box{
        display: flex;
        width: 25rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}