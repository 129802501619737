
#header-container
{
    background-color: #ffe484;
    border-bottom: 1px solid #fc9601;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3rem;
    z-index: 100;
}

.main-header-container{
    
}

.main-header-container-fixed{
    position: fixed;
    top: 0;
}

.left-header-container{
    padding-left: 1rem;
    display: flex;
}

.mid-header-container{
}

.jump-to-container{
    display: flex;
    flex-direction: row;
}

.mid-item{
    margin-left: 2px;
    margin-right: 2px;
    user-select: none;
    cursor: pointer;
}

.mid-item:hover{
    text-decoration: underline;
}

.right-header-container{
    padding-right: 1rem;
}

.link-container{
    text-decoration: none;
    color: inherit
}

.link-container:hover{
    color: #fc9601;
}

.photo-gallery-text{
    display: flex;
    align-items: center;
    justify-content: center;
}

.bold{
    font-weight: bold;
}